import React from "react";
import Layout from "../component/layout";
import ServicesHomeComponentV2 from "../component/servicesHomeComponentV2";
import HomeImage from "../images/python-dev-home-image.png";
import WebsLogoSwiper from "../component/websLogoSwiper";
import ItExpandTeamComponent from "../component/Services/IT/ItExpandTeamComponent";
import TimelineV1Component from "../component/TimelineV1Component";
import Icon from "../images/card-icon.png";
import CardsComponent from "../component/CardsComponent";
import TechStackComponent from "../component/Services/techStackComponent";
import CustomDevelopmentVideoComponent from "../component/Services/CustomDevelopment/customDevelopmentVideoComponent";
import AccordionComponent from "../component/accordionComponent";
import BookCallServiceComponent from "../component/bookCallServiceComponent";
import FooterComponent from "../component/footerComponent";

const servicePythonDev = () => {
  const seo = {
    title: "Python Development Outsourcing Company in USA | Motomtech",
    metaDesc:
      "Expert outsourced Python development teams available to build your next project, or augment your current development team.",
  };
  const cards = [
    {
      icon: Icon,
      title: "Creating Scripts in Python:",
      description:
        "We leverage Python's ease-of-use and simplicity to create powerful scripts that automate tasks, streamline processes, and enhance productivity within your organization.",
    },
    {
      icon: Icon,
      title: "Automate Tasks with Python:",
      description:
        "From automating mundane tasks to complex business processes, we help businesses boost efficiency and accuracy with Python-based automation solutions.",
    },
    {
      icon: Icon,
      title: "Python Data Science:",
      description:
        "Tap into the power of your data with our Python data science services. We use Python's powerful data processing libraries to extract meaningful insights, aiding in smarter decision making.",
    },
    {
      icon: Icon,
      title: "Python Test Automation:",
      description:
        "We utilize Python's capabilities to automate testing, ensuring your applications are reliable, robust, and bug-free. This leads to improved product quality and faster time-to-market.",
    },
    {
      icon: Icon,
      title: "Python ETL (Extract, Transform, Load):",
      description:
        " Our Python ETL services help you seamlessly extract, transform, and load data, ensuring efficient data flow between different systems and databases.",
    },
    {
      icon: Icon,
      title: "Machine Learning with Python:",
      description:
        "Harness the power of machine learning with Python to gain predictive insights, optimize operations, and create intelligent, automated systems.",
    },
    {
      icon: Icon,
      title: "Python Application Development:",
      description:
        "We use Python's versatility to develop high-performing, scalable applications that are tailored to your specific business needs.",
    },
    {
      icon: Icon,
      title: "Python Web Development:",
      description:
        "Leveraging Python's robust web development frameworks, we build dynamic, user-friendly websites and web applications that enhance your online presence and customer engagement.",
    },
    {
      icon: Icon,
      title: "Python Data Management:",
      description:
        "We offer Python-based data management solutions that ensure your data is organized, accessible, and secure, fostering improved data usage and governance.",
    },
    {
      icon: Icon,
      title: "Python Embedded Systems:",
      description:
        " Our team is skilled in creating Python-based embedded systems that offer seamless functionality, reliability, and performance in various applications.",
    },
  ];
  const questions1 = [
    {
      title: "Why use the Python programming language for web development?",
      description: (
        <>
          <p className="accordion-section__card-desc">
            Python is an excellent choice for web development for several
            reasons. Firstly, Python's simple and clean syntax allows for rapid
            development, making it ideal for startups and companies looking to
            launch their web applications quickly. Its readability and
            simplicity also mean lower maintenance costs, as Python code is
            generally easier to update and debug compared to other languages.
          </p>
          <p className="accordion-section__card-desc">
            Moreover, Python offers a host of robust web development frameworks,
            such as Django and Flask, which further simplify the web development
            process. These frameworks come with functionalities like URL
            routing, template engine, database schema migrations and more right
            out of the box, saving developers a significant amount of time.
            Furthermore, Python's extensive library support can handle tasks
            ranging from text processing to network programming, making it
            highly versatile for any web application needs.
          </p>
        </>
      ),
      eventKey: 1,
    },
    {
      title: "Why should I consider outsourcing my Python development?",
      description: (
        <>
          <p className="accordion-section__card-desc">
            Outsourcing your Python development can offer numerous benefits.
            Firstly, it gives you access to a vast pool of skilled Python
            developers who have a wide range of experience across various
            domains and projects. This ensures your project benefits from
            industry best practices and cutting-edge solutions.
          </p>
          <p className="accordion-section__card-desc">
            Secondly, outsourcing can be a cost-effective solution. It
            eliminates the overhead costs associated with hiring, training, and
            maintaining an in-house development team. You also save on
            infrastructure costs as the outsourcing partner would already have
            the necessary setup.
          </p>
          <p className="accordion-section__card-desc">
            Finally, outsourcing allows you to focus on your core business
            functions while leaving the technical aspects to the experts. It
            provides you with the flexibility to scale up or down based on
            project requirements, and guarantees professional project management
            to ensure your project is delivered on time and within budget.
          </p>
        </>
      ),
      eventKey: 2,
    },
  ];

  const questions2 = [
    {
      title: "How much does it cost to outsource Python development?",
      description: (
        <>
          <p className="accordion-section__card-desc">
            The cost of outsourcing Python development varies significantly
            depending on several factors such as the complexity and scope of the
            project, the experience level of the developers, the region where
            your outsourcing partner is based, and the duration of the project.
          </p>
          <p className="accordion-section__card-desc">
            For example, rates in regions like Eastern Europe or Asia can be
            more cost-effective than in North America or Western Europe due to
            lower living costs. Moreover, a simple Python script will obviously
            cost less than a complex web application or a machine learning
            algorithm.
          </p>
          <p className="accordion-section__card-desc">
            It's important to discuss your project requirements in detail with
            potential outsourcing partners to get a clear understanding of the
            costs involved. However, remember that while cost is an important
            factor, it's also crucial to consider the quality of work, the
            reliability of the provider, and their experience in Python
            development.
          </p>
        </>
      ),
      eventKey: 3,
    },
  ];
  return (
    <Layout seo={seo} title={seo.title}>
      <ServicesHomeComponentV2
        homeTitle={"Python Development Outsourcing Company"}
        homeDescription={
          "Execute your Python projects with ease by leveraging Motomtech’s team of expert Python developers. Get setup within days and start accelerating your Python project today."
        }
        homeImage={HomeImage}
        homeButton={"Request our services"}
        isGatsby={false}
      />
      <WebsLogoSwiper />
      <ItExpandTeamComponent
        title={
          "Outsource your Python Needs With Fully Customizable Python Development Services"
        }
        description1={
          "Choosing Motomtech for your Python development outsourcing needs is a decision that promises quality, efficiency, and innovative solutions. We understand that Python is a powerful, versatile language that can be used to create everything from simple scripts to complex, data-intensive applications. Our team of skilled developers possesses a deep knowledge of Python and is ready to apply their expertise to bring your vision to life."
        }
        description2={
          "When you choose to outsource Python development to Motomtech, you're choosing a partner that is committed to understanding and delivering on your unique business needs. We invest time in understanding your project requirements and your business as a whole, ensuring the Python solution we develop aligns perfectly with your objectives. Furthermore, our experience spans various industries, enabling us to infuse industry-specific best practices into your solution for better results."
        }
        description3={
          "Our experience in Python development can significantly benefit your projects. Having worked on numerous Python projects, we've honed our problem-solving skills, and our knowledge covers a broad spectrum of Python libraries and frameworks. This extensive experience allows us to anticipate and mitigate potential issues, ensuring a smooth development process. Moreover, we are always on top of the latest Python developments and trends, ensuring your solution is not only current but also forward-thinking."
        }
        description4={
          "Choosing Motomtech for your Python development needs means you're putting your project in capable, experienced hands. It means you're choosing a partner who is as invested in your success as you are. It means you're opting for a smooth, efficient development process that delivers a Python solution tailored to your needs. Let us handle your Python development needs so that you can focus on other critical aspects of your business. Trust in Motomtech, where we believe in delivering nothing short of excellence."
        }
      />
      <TimelineV1Component
        timelineTitle={"Python Development Services"}
        timelineTextTitle1={"Custom Python Development:"}
        timelineTextDescription1={
          "At Motomtech, we specialize in developing tailored Python applications that suit your unique business requirements. Whether you need a simple script, a complex data processing system, or an advanced web application, our team of skilled Python developers will build a solution that fulfills your specific needs."
        }
        timelineTextTitle2={"Python IT Augmentation Services:"}
        timelineTextDescription2={
          "Strengthen your existing team with our Python IT augmentation services. Our skilled Python experts can seamlessly integrate with your in-house team, offering their knowledge and expertise to drive your project forward, supplement gaps, and scale up as needed."
        }
        timelineTextTitle3={"Python QA Services:"}
        timelineTextDescription3={
          "Ensuring the quality of your Python application is at the forefront of our development process. Our Python QA services involve rigorous testing methodologies to identify and resolve potential issues, ensuring your application is robust, reliable, and bug-free."
        }
        timelineTextTitle4={"Python Maintenance and Support:"}
        timelineTextDescription4={
          "Our commitment to you extends beyond the initial development phase. We offer Python maintenance and support services to ensure that your applications remain current and operate smoothly. From routine updates to resolving any unexpected issues, we've got you covered."
        }
        timelineTextTitle5={"Python Consulting:"}
        timelineTextDescription5={
          "Unsure about the direction of your Python project? Our Python consulting services provide expert guidance to help you make informed decisions. We provide strategic advice on Python architecture, performance optimization, and best practices, ensuring you're on the right path towards achieving your business goals."
        }
      />
      <ItExpandTeamComponent
        title={"Why Leverage Python For Your Next Project?"}
        description1={
          "Python has proven itself to be a versatile, powerful, and intuitive language, making it an excellent choice for your next project. Its popularity among developers and business owners alike is due in large part to its simple and clean syntax, which enables rapid development. Python's readability makes it an ideal language for beginners, while its depth and flexibility also appeal to seasoned programmers. By removing the clutter and complexity often found in other languages, Python lets developers focus on what truly matters: building robust, functional applications."
        }
        description2={
          "Beyond its simplicity, Python is also highly flexible and expandable, boasting a wide array of libraries and frameworks that cover almost every development need. From web development frameworks like Django and Flask to scientific computing libraries like NumPy and SciPy, the extensive range of Python's ecosystem ensures that, no matter your project requirements, there's likely a Python tool that can help."
        }
        description3={
          "Python's interpretability is another factor that sets it apart. Python is an interpreted language, which means that code is executed line by line, making debugging easier and more efficient. This feature can significantly speed up the development process, as errors can be spotted and fixed as soon as they occur."
        }
        description4={
          "Python Enhancement Proposals (PEPs) also play a vital role in the Python community. PEPs are design documents that provide information to the Python community or describe a new feature for Python or its processes. They make Python a more organized and transparent language, allowing developers to understand the reasoning behind certain decisions and the future direction of the language."
        }
        description5={
          "Finally, Python is open source, which means that it's free to use and distribute, and its source code is accessible to the public. This open-source nature has fostered a large, active community of developers who are constantly contributing to Python's development and evolution. It also means that Python benefits from collective intelligence, as developers around the world can add features, fix bugs, and contribute to Python's growing ecosystem."
        }
        description6={
          "In conclusion, leveraging Python for your next project brings several advantages. Its simplicity, flexibility, interpretability, organized structure through PEPs, and open-source nature make it a robust and reliable language that can cater to various development needs."
        }
      />
      <CardsComponent
        title={"Outsourced Python Development Solutions"}
        description={
          "Motomtech is more than just a provider of Python development services. We are a strategic partner committed to delivering tangible, beneficial results to help transform your business. Our outsourced Python development solutions span a broad range of applications, each designed to maximize efficiency, boost performance, and drive innovation within your organization."
        }
        cards={cards}
      />
      <div style={{ position: "relative", zIndex: "3" }}>
        <TechStackComponent />
      </div>
      <CustomDevelopmentVideoComponent />
      <AccordionComponent
        arr1={questions1}
        arr2={questions2}
        title={"Frequently asked questions"}
        subtitle={"Here you can find some questions that our user ask te most."}
      />
      <div
        style={{
          background:
            "linear-gradient(108.34deg, #09062D -2.63%, #36498C 100%)",
        }}
      >
        <BookCallServiceComponent
          bookCallServiceTitle={"Jumpstart your startup with Motomtech"}
          bookCallServiceDescription={"Stop wasting time."}
          isFirstService={true}
          isSecondService={false}
        />
      </div>
      <FooterComponent />
    </Layout>
  );
};

export default servicePythonDev;
